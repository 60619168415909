
import { request } from "./pip3d_common";

const dbg = console.log;
const err = console.error;

const events = [ 'mousedown', 'touchend', 'keydown', 'gamepadconnected' ];

if (!window['RTCPeerConnection'])
    window['RTCPeerConnection'] = window['webkitRTCPeerConnection'];

function autoUnmute(cb) {
    const tryUnmute = () => {
        const audio = document.getElementById('audio');
        if (audio && (audio.muted || audio.paused)) {
            const playSuccess = () => {
                if (typeof cb === 'function') cb();
                for (const event of events)
                    window.removeEventListener(event, tryUnmute);
            };
            const playError = (error) => {
                err("audio: failed to unmute & play, was it a real interaction?");
                audio.muted = true;
            };
            try {
                audio.muted = false;
                // needed as autoplay seems broken on chrome
                const p = audio.play();
                // some browser do not return a promise
                if (p && p.then)
                    p.then(playSuccess).catch(playError);
                else
                    playSuccess();
            } catch(error) {
                playError(error);
            }
        }
    };
    for (const event of events)
        window.addEventListener(event, tryUnmute);
    tryUnmute();
}

export function getRTCPeerConnectionConf(iceServers, controllerUrl) {
    let conf = {
        sdpSemantics: 'unified-plan', // needed on tizen
    };

    let promises = [];
    if (iceServers && iceServers.length > 0) {
        conf.iceServers = [];
        for (const url of iceServers.split(',')) {
            if (url.length == 0) continue;
            let iceServer = { urls: url };
            if (url.startsWith("turn:")) {
                if (!controllerUrl) {
                    err("audio: cannot get TURN credentials");
                    continue;
                }
                const url = new URL(controllerUrl);
                const credentialsUrl = url.protocol + '//' + url.host + url.pathname + '/credentials' + url.search;
                const [ xhr, req ] = request('GET', credentialsUrl);
                promises.push(req.then(res => {
                    const data = JSON.parse(res);
                    iceServer.username = data.turnLogin.username;
                    iceServer.credential = data.turnLogin.credential;
                    conf.iceServers.push(iceServer);
                }).catch((error) => {
                    err("audio: failed to add ice server", url, error);
                }));
            } else
                conf.iceServers.push(iceServer);
        }
    }

    return Promise.all(promises).then(() => { return conf; });
}

let currentCtx;
export function start2(settings) {
    if (currentCtx) _stop(currentCtx);

    const ctx = {
        settings: settings,
    };
    currentCtx = ctx;

    return new Promise((resolve, reject) => {
        const getWebRTCConf = settings.getWebRTCConf || function() {
            return getRTCPeerConnectionConf(settings.iceServers, settings.signalingUrl);
        };
        const tryConnect = () => {
            if (ctx.stopping) {
                reject(new Error('audio: try to connect while WebRTC is stopping'));
                return;
            }
            dbg("audio: starting");
            // get a new conf at each connection tentative to get new TURN credentials
            return getWebRTCConf().then(conf => {
                dbg("audio: WebRTC conf", conf);
                ctx.pc = new RTCPeerConnection(conf);
                return connectSignalingChannel(ctx).then(() => {
                    let connectTimeout;
                    if (settings.connectTimeout) {
                        if (connectTimeout) clearTimeout(connectTimeout);
                        connectTimeout = setTimeout(() => {
                            const error = new Error("audio: connection timeout");
                            // async stop as it waits for gathering end
                            _stop(ctx);
                            if (!settings.disableRetry) {
                                setTimeout(() => { throw error });
                                tryConnect();
                            } else
                                reject(error);
                        }, settings.connectTimeout);
                    }
                    return connectWebRtc(ctx).then(() => {
                        if (connectTimeout) clearTimeout(connectTimeout);
                        dbg("audio: connected");
                        resolve(ctx);
                    }).catch(reject);
                }).catch(function(error) {
                    err("audio: connection error", error);
                    if (!settings.disableRetry) {
                        setTimeout(tryConnect, 1000);
                        setTimeout(() => { throw error });
                    } else
                        reject(error);
                });
            });
        };
        return tryConnect();
    });
}

function connectSignalingChannel(ctx) {
    const pc = ctx.pc;
    const signalingUrl = ctx.settings.signalingUrl.replace("http", "ws");
    dbg("audio: connecting signaling channel");
    const ws = new WebSocket(signalingUrl);
    ws.onmessage = event => {
        const msg = JSON.parse(event.data);
        switch (msg.type) {
            case "sdp":
                const answer = JSON.parse(msg.data);
                dbg("WebRTC answer", answer);
                pc.setRemoteDescription(new RTCSessionDescription(answer));
                break;
            case "candidate":
                const candidate = JSON.parse(msg.data);
                dbg("audio: WebRTC remote candidate", candidate);
                pc.addIceCandidate(new RTCIceCandidate(candidate));
                break;
        }
    };
    ws.onclose = event => {
        dbg("audio: signaling channel closed");
        ctx.ws = null;
    };
    ctx.ws = ws;
    return new Promise((resolve, reject) => {
        ws.onopen = () => {
            dbg("audio: signaling channel opened");
            resolve();
        };
        ws.onerror = error => {
            err("audio: signaling channel error", error);
            reject(error);
        };
    });
}

function connectWebRtc(ctx) {
    dbg("audio: connecting WebRTC");
    return new Promise((resolve, reject) => {
        const pc = ctx.pc;
        pc.ontrack = e => {
            if (ctx.settings.stateCb) ctx.settings.stateCb('track');
            e.receiver.playoutDelayHint = 0;
            document.getElementById(e.track.kind).srcObject = e.streams[0];
        }

        pc.oniceconnectionstatechange = e => {
            dbg("audio: ICE connection state", pc.iceConnectionState);
            switch (pc.iceConnectionState) {
                case 'connected':
                    if (ctx.ws) ctx.ws.close();
                    if (ctx.settings.stateCb) ctx.settings.stateCb('connected');
                    resolve();
                    break;
                case 'failed':
                    reject(new Error('ICE connection failed'));
                    if (ctx.settings.stateCb) ctx.settings.stateCb('failed');
                    break;
            }
        }
        pc.onconnectionstatechange = e => {
            dbg("audio: Connection state", pc.connectionState);
            switch (pc.connectionState) {
                case 'failed':
                    reject(new Error('connection failed'));
                    if (ctx.settings.stateCb) ctx.settings.stateCb('failed');
                    break;
            }
        };
        pc.onicecandidate = event => {
            if (event.candidate === null || !ctx.ws) return;
            dbg("audio: candidate", JSON.stringify(event.candidate));
            const message = { type: "candidate", data: JSON.stringify(event.candidate) };
            ctx.ws.send(JSON.stringify(message));
        };

        let negotiating = false;
        function negotiationneeded() {
            if (negotiating) return;
            negotiating = true;
            dbg("audio: WebRTC (re)negotiation")
            function sendOffer() {
                dbg("audio: WebRTC offer", JSON.stringify(pc.localDescription));
                const message = { type: "sdp", data: JSON.stringify(pc.localDescription) };
                ctx.ws.send(JSON.stringify(message));
                negotiating = false;
            }
            function failed(error) {
                err("audio: negotiation failed", error);
                negotiating = false;
            }
            try {
                pc.createOffer().then(d => {
                    return pc.setLocalDescription(d).then(sendOffer);
                }).catch(failed);
            } catch (error) {
                err("audio: createOffer failed", error, "retry with deprecated version");
                pc.createOffer((d) => {
                    pc.setLocalDescription(d, sendOffer, failed);
                }, failed, { offerToReceiveAudio: true });
            }
        }

        pc.onicegatheringstatechange = e => dbg("audio: ICE gathering state", pc.iceGatheringState)
        pc.onicecandidateerror = e => dbg("audio: ICE candidate error", e)
        pc.onnegotiationneeded = negotiationneeded;
        pc.onsignalingstatechange = e => dbg("audio: Signaling state", pc.signalingState)

        if (ctx.settings.unmuteCb)
          autoUnmute(ctx.settings.unmuteCb);

        try {
            pc.addTransceiver('audio', {'direction': 'recvonly'})
        } catch (error) {
            err("audio: failed to add transceiver", error);
        }
        negotiationneeded();
    });
}

function _stop(ctx) {
    const pc = ctx.pc;
    if (ctx.ws) ctx.ws.close();
    if (pc) {
        // remove handlers synchronously
        pc.ontrack =
        pc.oniceconnectionstatechange =
        pc.onicecandidate =
        pc.onconnectionstatechange =
        pc.onicegatheringstatechange =
        pc.onicecandidateerror =
        pc.onnegotiationneeded =
        pc.onsignalingstatechange =
            undefined;
        return new Promise(resolve => {
            if (pc) pc.close();
            resolve();
        });
    } else
        return Promise.resolve();
}

export function stop2(ctx) {
    dbg("audio: disconnecting")
    ctx = ctx || currentCtx;
    if (!ctx || ctx.stopping) return;
    ctx.stopping = true;
    return _stop(ctx).then(function() {
        dbg("audio: disconnected")
    });
}

// legacy api
export function start(signalingUrl, conf, stateCb, unmuteCb, timeout) {
    const settings = {
        signalingUrl: signalingUrl,
        getWebRTCConf: () => { return Promise.resolve(conf) },
        stateCb: stateCb,
        unmuteCb: unmuteCb,
        connectTimeout: timeout,
    };
    start2(settings);
    return true;
}

export function stop(isRestart) {
    return stop2();
}
