
export function request(method, uri, data, responseType = null) {
    const xhr = new XMLHttpRequest();
    return [ xhr, new Promise((resolve, reject) => {
        xhr.open(method, uri);
        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300)
                resolve(xhr.response);
            else
                reject(xhr.response);
        };
        xhr.onerror = () => reject(xhr.response);
        xhr.onabort = () => resolve();
        if (responseType) xhr.responseType = responseType;
        xhr.send(data);
    }) ];
}

export function getTextureData(canvas, filetype, width, height, nb_components, image, aimage) {

    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext("2d", {
        willReadFrequently: true,
    });

    ctx.drawImage(image, 0, 0);
    let data = ctx.getImageData(0, 0, width, height).data;

    if (aimage) {
        ctx.drawImage(aimage, 0, 0);
        var adata = ctx.getImageData(0, 0, width, height).data;
        for (var i = 0; i < width * height; i++)
            data[i*4 + 3] = adata[i*4];
    } else if (nb_components == 1 || nb_components == 2) {
        var adata = new Uint8Array(width*height*nb_components);
        if (nb_components == 2 && filetype === 'png') // we use PNG_COLOR_TYPE_GRAY_ALPHA for encoding 2 components png images
            for (var i = 0; i < width * height; i++) {
                adata[i*2] = data[i*4];
                adata[i*2+1] = data[i*4+3];
            }
        else
            for (var i = 0; i < width * height; i++)
                for (var j = 0; j < nb_components; j++)
                    adata[i*nb_components+j] = data[i*4+j];
        data = adata;
    }

    return data;
}
